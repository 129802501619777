import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Button from '../../components/Atoms/Button';
import Claim from '../../services/ClaimService';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { ServiceProvider } from '../../services/index';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { CENTER_DOCS, REPAIR_DOCS } from '../../constants/Constants.js';
import PartnerDocument from '../../components/Accident/PartnerDocument.js';
import SendSMS from './SendSMS.jsx';
import SendPush from './SendPush.jsx';
import ImageBox from '../../components/Common/Claim/ImageBox.jsx';
const partnerService = ServiceProvider.partner;

const ApprovalDialog = () => {
  const myInfo = useRecoilValueLoadable(myInfoSelector);
  const partner = myInfo.contents.roleCode === 'D_ADM';
  const aid = myInfo.contents.associationInfo?.associationId;
  const history = useHistory();
  const today = dayjs(new Date()).format('YYYY-MM-DD');
  const [loading, setLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    carNbr: '',
    carVIN: '', // 차대번호
    newCarNbr: '', // 변경된 차량번호
    customerName: '', // 소유자
    customerContact: '', // 연락처
    association: { value: '' },
    warranty: { value: '' },
    receiptDate: null, // 접수일자
    addType: { value: '' },
    note: '', //  증상 입력
    vehicleValue: '', // 차량가액
    checkDate: '', // 점검일자
    checkDistance: '', // 성능 킬로수
    distanceDriven: '', // 접수 킬로수
    buyDate: '', // 인도일자
    elapsedDays: '', // 경과일수
  }); // 신규 등록
  const [detailData, setDetailData] = useState({}); // claimData 기존고객
  const [docs, setDocs] = useState([]); // claimDocs
  const [warrantyData, setWarrantyData] = useState({
    buyDate: null,
    checkDistance: 0,
    distanceDriven: 0,
    diagnosisType: [],
    reservationDate: '',
    producingCountry: 'D',
    carName: '',
    partnerInfo: [
      {
        partnerName: '',
        partnerType: '', // 진단점 P_D, 수리점 P_R, 둘다 P_DR
        claimStatusCode: '',
        receiptDate: '',
        dueDate: '',
        paymentAmt: 0,
        carNbr: '',
        statementNumber: '',
        checkDate: '',
        partnerId: 0,
        repairCode: '',
        reservationDate: '',
        documentGroupId: '',
        useYn: '',
        fileData: [],
      },
    ],
    radius: 0,
    partnerNote: '', // opt
  });

  const [partnerAllList, setPartnerAllList] = useState([]);
  const [fileItems, setFileItems] = useState([]); // 모든 사진 파일
  const [deleteImgs, setDeleteImgs] = useState([]); // 모든 삭제하는 사진 파일
  const [partnerDeleteImgs, setPartnerDeleteImgs] = useState([]); // 삭제하는 파트너 파일
  const [partnerFile, setPartnerFile] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [statusCode, setStatusCode] = useState('Z01');
  const [pushLoading, setPushLoading] = useState(false);
  const [toggleStates, setToggleStates] = useState([
    { idx: 3, status: 'Z03', value: false }, // 서류완료
    { idx: 4, status: 'Z04', value: false }, // 거절완료
    { idx: 5, status: 'Z05', value: false }, // 입고안내 완료
    { idx: 6, status: 'Z06', value: false }, // 입고점검 완료 - 진단점앱에서 사진 올라온 경우
    { idx: 7, status: 'Z07', value: false }, // 수리중 - 승인견적서, 승인 보내기 완료 시
    { idx: 8, status: 'Z08', value: false }, // 수리완료 - 진단점앱에서 수리사진 올라온 경우
  ]);

  const [repairData, setRepairData] = useState({
    date: '',
    maintenanceCode: '',
    workYN: '',
    workFee: null, // int
    repairNote: '',
  }); // 수리정보

  const [insuranceInfo, setInsuranceInfo] = useState({
    payDate: '',
    paymentAmt: null, // int
    deductibleAmt: null, // int
    completeDate: '',
    insurancePolicyNumber: '', // 증권번호
    code: '10',
  });

  const [showSmsMoal, setShowSmsModal] = useState(false);
  const [showSmsType, setShowSmsType] = useState('');
  const [showPushModal, setShowPushModal] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState(null);
  const [showPushType, setShowPushType] = useState('');
  const [smsData, setSmsData] = useState({
    msgType: '',
    sourceAddr: '15332729',
    msgSubType: null,
    destAddr: '',
    subject: null, //opt,
    contents: '',
    fileData: null, //opt
    url: null, // opt
  });
  const [newStatusCode, setNewStatusCode] = useState(null);
  const { id } = useParams();
  const [userNames, setUserNames] = useState({
    firstId: '',
    firstName: '',
    lastId: '',
    lastName: '',
  });

  const handleToggleButton = (name, checked) => {
    if (!detailData?.dataId) return;
    setToggleStates((prev) =>
      prev.map((state) =>
        state.status === name ? { ...state, value: checked } : state
      )
    );

    if (!checked) {
      const idx = Number(name.slice(1, 3));
      if (idx === 4 || idx === 5) {
        setStatusCode(`Z03`);
        setNewStatusCode(`Z03`);
      } else {
        setStatusCode(`Z0${idx - 1}`);
        setNewStatusCode(`Z0${idx - 1}`);
      }
    } else {
      setStatusCode(name);
      setNewStatusCode(name);
    }
  };

  function calculateElapsedDays(targetDateString) {
    const today = dayjs();
    const targetDate = dayjs(targetDateString); // 계산할 대상 날짜 (문자열로 입력)
    const elapsedDays = today.diff(targetDate, 'day'); // 두 날짜의 차이를 '일' 단위로 계산
    return elapsedDays;
  }

  useEffect(() => {
    if (statusCode) {
      const index = Number(statusCode.slice(1, 3));
      setToggleStates((prevStates) =>
        prevStates.map((state) => {
          if (state.idx <= index) {
            return { ...state, value: true };
          } else {
            return { ...state, value: false };
          }
        })
      );
    }
  }, [statusCode]);

  const [partnerAllType, setPartnerAllType] = useState([]);
  const getPartnerAllType = async () => {
    try {
      const data = await Claim.getDocsType('R');
      setPartnerAllType(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPartnerAllType();
  }, []);

  const getPartnerAllList = async (aid) => {
    try {
      const data = await partnerService.list({
        aids: aid ?? 1,
        page: 1,
        size: 200,
        useYN: 'Y',
      });
      if (data) {
        setPartnerAllList(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!aid) {
      getPartnerAllList(aid);
    }
  }, [aid]);

  const [statusList, setStatusList] = useState([]);
  const getStatusList = async () => {
    try {
      const data = await Claim.getDocsType('Z');
      setStatusList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStatusList();
  }, []);

  useEffect(() => {
    if (history.location.state) {
      setCustomerInfo(history.location.state.customerInfo);
    }
  }, [history.location.state]);

  const [copyPartnerInfo, setCopyPartnerInfo] = useState([]);
  const getDetailData = async (dataId) => {
    setLoading(true);
    try {
      const data = await Claim.getDetail(dataId);
      if (data) {
        setDetailData(data.claimData);
        setDocs(data.claimDocuments);
        setCustomerInfo({
          carName: data.claimData?.carName ?? '',
          newCarNbr: data.claimData?.newCarNbr ?? '',
          customerName: data.claimData?.customerName ?? '',
          customerContact: data.claimData?.customerContact ?? '',
          carVIN: data.claimData?.vin ?? '',
          carNbr: data.claimData?.carNbr ?? '',
          association: {
            value: !!data.claimData?.checkFlag
              ? data.claimData.checkFlag === 'Y'
                ? 'association'
                : 'non_association'
              : null,
          },
          warranty: {
            value: !!data.claimData?.assuranceType
              ? data.claimData.assuranceType === '2'
                ? 'warranty'
                : 'warranty_s'
              : null,
          },
          receiptDate: data.claimData?.receiptDate
            ? CommonUtil.Formatter.stringToDayForm(data.claimData.receiptDate)
            : today,
          addType: {
            value: data.claimData?.addType ?? '',
          },
          note: data.claimData?.note ?? '',
          vehicleValue: formatNumber(data.claimData?.vehicleValue) ?? '',
          checkData: data.claimData?.checkData ?? null,
          checkDistance: data.claimData?.checkDistance ?? null,
          distanceDriven: data.claimData?.distanceDriven ?? null,
          buyDate: data.claimData?.buyDate
            ? CommonUtil.Formatter.stringToDayForm(data.claimData.buyDate)
            : null,
          elapsedDays: data.claimData?.buyDate
            ? calculateElapsedDays(data.claimData.buyDate)
            : 0,
        });
        setCopyPartnerInfo(data.claimData.partnerInfo);
        setWarrantyData({
          buyDate: !!data.claimData.buyDate ? data.claimData.buyDate : null,
          checkDistance: data.claimData.checkDistance,
          distanceDriven: data.claimData.distanceDriven,
          diagnosisType: data.claimData.diagnosisType
            ? data.claimData.diagnosisType
            : null,
          reservationDate: data.claimData.reservationDate,
          producingCountry: data.claimData.producingCountry,
          carName: data.claimData.carName,
          partnerInfo: data.claimData.partnerInfo
            ? data.claimData.partnerInfo
            : null,
          partnerNote: data.claimData.partnerNote,
        });
        setStatusCode(
          data.claimData.claimStatus === 'Z1'
            ? 'Z01'
            : data.claimData.claimStatus
        );
        setRepairData({
          date: data.claimData.partnerReceiptDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.partnerReceiptDate
              )
            : '',
          maintenanceCode: data.claimData.maintenanceCode
            ? data.claimData.maintenanceCode.split(',')
            : '',
          workYN: data.claimData.workYN,
          workFee: data.claimData.workFee,
          repairNote: data.claimData.repairNote,
          repairOpinion: data.claimData.repairOpinion
            ? data.claimData.repairOpinion
            : '',
        });
        setInsuranceInfo({
          payDate: data.claimData.insurancePurchaseDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.insurancePurchaseDate
              )
            : '',
          paymentAmt: data.claimData.insurancePaymentAmt, // int
          deductibleAmt: data.claimData.deductibleAmt, // int
          completeDate: data.claimData.completeDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.completeDate)
            : '',
          insurancePolicyNumber: data.claimData.insurancePolicyNumber
            ? data.claimData.insurancePolicyNumber
            : '',
          code: data.claimData.insuranceCode
            ? data.claimData.insuranceCode
            : insuranceInfo.code,
        });
      }
      if (myInfo.contents) {
        setUserNames({
          firstId: data.claimData.receiptId ?? null,
          firstName: data.claimData.receiptName
            ? data.claimData.receiptName
            : null,
          lastId: myInfo.contents.userId,
          lastName: myInfo.contents.userName,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (myInfo.contents) {
      setUserNames((prev) => ({
        ...prev,
        lastId: myInfo.contents.userId,
        lastName: myInfo.contents.userName,
      }));
    }
  }, [myInfo.contents]);

  const filterPartnerTypeOptions = (partnerAllType, repairCodes) => {
    if (!repairCodes) return [];
    return partnerAllType
      .filter((item) => repairCodes.includes(item.value))
      .map((item) => item.label)
      .toString();
  };

  //ANCHOR - set partner info
  useEffect(() => {
    if (!!warrantyData.partnerInfo.length && !!partnerAllList.length) {
      const data = warrantyData.partnerInfo
        .map((el) => {
          const partner = partnerAllList.find(
            (partner) => Number(el.partnerId) === partner.partnerId
          );
          if (partner) {
            let newSelectPartner = {
              partnerName: partner?.partnerName,
              partnerType: el?.partnerType.split(',') ?? '', // 진단점 P_D, 수리점 P_R, 둘다 P_DR
              claimStatusCode: el?.claimStatusCode ?? null,
              receiptDate: el?.receiptDate
                ? CommonUtil.Formatter.stringToDate(el?.receiptDate)
                : null,
              dueDate: el.dueDate
                ? CommonUtil.Formatter.stringToDate(el.dueDate)
                : '',
              paymentAmt: el?.paymentAmt ?? null,
              checkDate: el?.checkDate ?? null,
              partnerId: partner.partnerId,
              reservationDate: el?.reservationDate
                ? CommonUtil.Formatter.stringToDate(el?.reservationDate)
                : null,
              documentGroupId: el?.documentCode ?? null,
              useYn: 'Y',
              address: partner.address,
              cellphone: partner.cellphone,
              isOriginal: true,
              partnerDocuments: el.partnerDocuments,
              partnerShip: partner?.partnerShip,
            };
            newSelectPartner.repairCodesName = filterPartnerTypeOptions(
              partnerAllType,
              partner.partnerRepairType.split(',')
            );
            newSelectPartner.repairCodes = el.repairCode
              ? el.repairCode.split(',')
              : [];
            return newSelectPartner;
          }
          return null;
        })
        .filter(Boolean);
      setSelectedPartner(data.flat());
    } else {
      setSelectedPartner([]);
    }
  }, [warrantyData.partnerInfo, partnerAllList, partnerAllType]);

  //ANCHOR -  클레임 POST, PUT
  const registerClaim = async (type) => {
    setLoading(true);

    const fileData = [];
    const files = [];

    let fileDataList = [];
    let filesList = [];

    if (type === '저장') {
      fileItems.map((el) =>
        fileData.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      fileItems.map((el) => files.push(el.file));
    } else if (type === '수정') {
      const del = [];
      if (!!deleteImgs.length) {
        const imgs = deleteImgs.filter((el) => !!el.imgId);

        if (!!imgs.length) {
          imgs.map((el) =>
            del.push({
              imgId: el.imgId,
              documentCode: el.documentCode,
              state: 'D',
            })
          );
        }
        fileDataList = del;
      }

      const findFile = fileItems.find((el) => el.file);
      const findImgId = fileItems.find((el) => el.imgId);
      const idFile = [];
      fileItems.filter((el) => el.imgId && idFile.push(el));
      const imgFile = [];
      fileItems.filter((el) => el.file && imgFile.push(el));

      if (findFile && !findImgId) {
        fileItems.map((el) =>
          fileDataList.push({
            documentCode: el.documentCode,
            state: el.state,
          })
        );
        fileItems.map((el) => filesList.push(el.file));
      } else if (findFile && findImgId) {
        imgFile.map((el) =>
          fileDataList.push({
            documentCode: el.documentCode,
            state: el.state,
          })
        );
        const { fileData, files } =
          CommonUtil.File.convertForServerClaim(deleteImgs);
        files.map((el) => filesList.push(el));
        imgFile.map((el) => filesList.push(el.file));
      }
    }

    let partnerFiles = [];

    const modifyPartners = selectedPartner.map((partner) => {
      const { fileData, files } = CommonUtil.File.convertForServerClaimPartner(
        partner.partnerDocuments
      );
      partner.partnerDocuments = fileData;
      files.map((el) => partnerFiles.push(el));
      return partner;
    });

    let partnerInfoData = [];
    if (!!modifyPartners) {
      partnerInfoData = modifyPartners.map((el) => ({
        partnerName: el?.partnerName?.split(' (')[0],
        carNbr: customerInfo.carNbr,
        statementNumber: detailData?.statementNumber,
        checkDate: detailData.checkDate,
        partnerId: el.partnerId,
        repairCode: el.repairCodes?.toString(),
        reservationDate: !!el.reservationDate
          ? CommonUtil.Formatter.dateToString(el.reservationDate)
          : null,
        useYn: el.useYn,
        documentGroupId: detailData?.documentGroupId ?? null,
        fileData: el.partnerDocuments ?? [],
        partnerType: 'P_DR',
        claimStatusCode: el?.claimStatusCode ?? null,
        receiptDate: !!el.receiptDate
          ? CommonUtil.Formatter.dateToString(el.receiptDate)
          : null,
        dueDate: !!el?.dueDate ? dayjs(el?.dueDate).format('YYYYMMDD') : null,
        paymentAmt: !!el?.paymentAmt ? Number(el?.paymentAmt) : null,
        partnerShip: el.partnerShip ?? 'N',
      }));
    }

    const Data = {
      dataId: detailData?.dataId,
      shopId: detailData.shopId ?? null,
      associationId: aid,
      addType: detailData.addType,
      funnelsType: detailData.funnelsType, // W or A
      claimStatus: statusCode,
      customerName: detailData?.customerName,
      customerContact: detailData?.customerContact?.replace(/[^0-9]/g, ''),
      checkFlag: detailData?.checkFlag,
      assuranceType: detailData?.assuranceType,
      statementNumber: detailData.statementNumber, // opt
      carNbr: detailData.carNbr,
      vin: detailData.vin,
      newCarNbr: detailData.newCarNbr,
      vehicleValue: detailData.vehicleValue,
      checkDate: detailData?.checkDate, // opt
      buyDate: detailData.buyDate,
      checkDistance: detailData.checkDistance,
      distanceDriven: detailData.distanceDriven,
      producingCountry: detailData.producingCountry,
      carName: detailData.carName,
      carAddress: detailData.carAddress,
      carLocation: detailData.carLocation, //Opt
      diagnosisType: detailData.diagnosisType, // 진단타입 B, D..
      consultationGroupId: detailData?.consultationGroupId,
      partnerInfo: !!partnerInfoData.length ? partnerInfoData : null, //Opt 진단점 목록
      receiptId: userNames.firstId ? userNames.firstId : myInfo.contents.userId,
      receiptName: userNames.firstName
        ? userNames.firstName
        : myInfo.contents.userName,
      receiptDate: customerInfo?.receiptDate
        ? dayjs(customerInfo.receiptDate).format('YYYYMMDD')
        : dayjs(today).format('YYYYMMDD'),
      documentGroupId: type === '저장' ? null : detailData?.documentGroupId, //Opt
      note: customerInfo.note ?? null, //Opt
      partenerReceiptDate: detailData?.partenerReceiptDate ?? null, //Opt 수리 입고일자
      maintenanceCode: !!repairData.maintenanceCode.length
        ? repairData.maintenanceCode.join()
        : null,
      workYN: detailData.workYN, //Opt 수리여부
      workFee: detailData.workFee, //Opt 협의비용
      insuranceCode: detailData.code, // opt 보험코드
      insurancePolicyNumber: detailData.insurancePolicyNumber ?? null, //opt 증권번호
      insurancePurchaseDate: detailData.insurancePolicyNumber ?? null, //Opt 보험료 지급일
      insurancePaymentAmt: detailData.paymentAmt ?? null, //Opt 보험료
      deductibleAmt: detailData.deductibleAmt ?? null, // opt 자기부담금
      partnerNote: detailData.partnerNote ? warrantyData.partnerNote : null, //Opt
      repairNote: detailData.repairNote ? repairData.repairNote : null,
      repairOpinion: detailData.repairOpinion ? repairData.repairOpinion : null, // Opt 소견내용
      completeYN: detailData.completeYN, //Opt 완료여부
      completeDate: detailData.completeDate, //Opt 완료일
      completeLoginId: myInfo.contents.loginId, //Opt 로그인유저 아이디
      completeName: myInfo.contents.userName, //Opt 로그인유저 이름
      completeLoginContact: myInfo.contents.contact, //opt 로그인유저 연락처
      fileData: fileDataList, //Otp
    };

    try {
      const data = await Claim.updateClaim(Data, filesList, partnerFiles);
      if (data) {
        window.cerp.toast.success('저장이 완료 되었습니다.');
        getDetailData(data.dataId);
        setDeleteImgs([]);
        setPartnerDeleteImgs([]);
        sessionStorage.removeItem('images');
        setLoading(false);
        setNewStatusCode(null);
        setFileItems([]);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(
        '저장 실패',
        '페이지 새로고침 후 다시 시도해주세요.'
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!newStatusCode) {
      registerClaim('수정');
    }
  }, [newStatusCode]);

  useEffect(() => {
    if (!!id) {
      getDetailData(id);
    }
  }, [id]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat('ko-KR').format(number);
  };

  const postSms = async (title) => {
    setLoading(true);
    const noUrl =
      title === '고객 서류 재요청 안내' ||
      title === '보증 거절 안내' ||
      title === '입고점 정보 안내';

    const postData = {
      msgType: smsData.msgType ? smsData.msgType : 'lms',
      msgSubType: smsData.msgType === 'at' ? smsData.msgSubType : null, //opt
      sourceAddr: smsData.sourceAddr,
      destAddr: smsData.destAddr.replaceAll('-', ''),
      subject: smsData.subject, // opt
      contents: smsData.contents,
      fileData: smsData.fileData,
      url: noUrl ? null : smsData.url,
      msgAuthType: 'SR',
      claimDataId: detailData.dataId,
    };

    try {
      const data = await Claim.postSMS(postData);
      if (data) {
        window.cerp.toast.success('메세지 전송이 완료되었습니다.');
        registerConsultation(title);
        setShowSmsModal(false);
        setLoading(false);
        if (title === '수리승인 및 양식 다운로드 안내') {
          handleToggleButton('Z07', true);
        } else if (
          title === '입고점 정보 안내' ||
          title === '진단점 고객정보 안내'
        ) {
          handleToggleButton('Z05', true);
        } else if (title === '보증 거절 안내') {
          handleToggleButton('Z04', true);
        }
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.warn(error?.response?.data.error?.message);
      setLoading(false);
    }
    setLoading(false);
  };

  const postPushMessage = async (pushType, missingDocs = null) => {
    setPushLoading(true);
    const id =
      pushType === 'C'
        ? `${detailData.carNbr}_${detailData.customerContact}`
        : partnerInfo?.loginId;

    const isDocType =
      showPushType === 'CENTER_DOCS' ? CENTER_DOCS : REPAIR_DOCS;

    let labels;
    let missingDocsMessage;
    if (missingDocs) {
      labels = missingDocs.map((val) => {
        const doc = isDocType.find((item) => item.value === val);
        return doc ? doc.label : val;
      });

      missingDocsMessage =
        labels.length > 0
          ? `${customerInfo?.carNbr} 차량 | ${labels.join(', ')} 서류 미비 알림`
          : `${customerInfo?.carNbr} 차량 | 서류 미비 알림`;
    }

    try {
      const reqData = {
        pushType: pushType,
        regId: id,
        msgType: pushType === 'C' ? '1' : '2',
        dataId: detailData.dataId,
        sendMsg: pushType === 'C' ? null : missingDocsMessage,
      };

      const data = await Claim.pushMessage(reqData);
      if (data) {
        window.cerp.toast.success('푸시 전송이 완료되었습니다.');
        const text =
          pushType === 'C' ? '고객 입고점 안내 앱푸시' : missingDocsMessage;
        registerConsultation(text);
      }
    } catch (error) {
      window.cerp.toast.error(error?.response?.data?.error?.message);
    } finally {
      setPushLoading(false);
      setShowPushModal(false);
    }
  };

  const registerConsultation = async (text) => {
    const id = detailData?.consultationGroupId;
    try {
      await Claim.posetConsultation({
        consultationGroupId: id,
        consultationDetails: text,
      });
    } catch (error) {
      console.log(error);
      window.cerp.toast.warn(error?.response.data.error?.message);
    }
  };

  const filterDocuments = (partnerDocuments, documentCode) => {
    return partnerDocuments?.filter((doc) => doc.documentCode === documentCode);
  };

  const [downloadLoading, setDownloadLoading] = useState(false);
  const saveFilesAsZip = async (partnerData) => {
    setDownloadLoading(true);
    const listImg = filterDocuments(partnerData.partnerDocuments, 'P_LIST');
    const files = [...listImg];
    const folderName = `${partnerData.partnerName}_${customerInfo?.carNbr}_견적서`;

    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');

    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);
    const imagePromises = files.map(async (file, index) => {
      const imgBlob = await Claim.getBlobFromUrl(file.filePath);
      const isImage = /\.(jpg|jpeg|png|gif|webp)$/i.test(file.originalFileName);

      const fileName = isImage ? `img_${index + 1}.jpg` : file.originalFileName;
      imagesFolder.file(fileName, imgBlob, { binary: true });
    });
    await Promise.all(imagePromises);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    setDownloadLoading(false);
    window.cerp.toast.info('다운로드가 완료되었습니다.');
  };

  useEffect(() => {
    if (!!selectedPartner.length) {
      const initValue = 0;
      const sumValeu = selectedPartner
        .filter((el) => el.useYn === 'Y')
        ?.reduce(
          (acc, cur) =>
            Number(acc) + Number(cur?.paymentAmt ? cur?.paymentAmt : 0),
          initValue
        );

      setInsuranceInfo((prev) => ({ ...prev, paymentAmt: sumValeu }));
    } else {
      setInsuranceInfo((prev) => ({ ...prev, paymentAmt: 0 }));
    }
  }, [selectedPartner.length, selectedPartner, warrantyData.partnerInfo]);

  return (
    <>
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      ></BlockUI>
      <div className="px-3 py-2 fixed surface-200 w-full z-5 border-bottom-1 border-400">
        <div className="flex md:justify-content-between justify-content-start">
          <div className="flex align-items-center gap-2 ">
            <div className="font-bold ml-2 text-xl md:block hidden">
              성능 보험 승인 관리
            </div>
          </div>
          <div className="flex gap-4">
            {partner ? null : (
              <div className="flex gap-2">
                <div className="flex align-items-center">*업무 진행상태 :</div>
                <Dropdown
                  value={statusCode}
                  onChange={(e) => {
                    setStatusCode(e.target.value);
                  }}
                  options={statusList}
                  optionLabel="label"
                  placeholder="상태 선택"
                  className="w-full md:w-16rem font-bold overflow-hidden"
                />
              </div>
            )}
            <Button.Default
              className="sm:text-lg sm:text-base"
              icon="pi pi-save"
              label={'저장'}
              onClick={() => registerClaim('수정')}
            />
            <Button.Default
              className="p-button-outlined p-button-danger"
              icon="pi pi-times"
              label=""
              onClick={() => {
                sessionStorage.removeItem('images');
                sessionStorage.removeItem('data');
                history.push('/compensation/approval/list');
              }}
            />
          </div>
        </div>
      </div>
      <div id="performance-check-report" className="claimPaper">
        <div className="inner">
          {/* 기본 정보 테이블 */}
          <h3 className="font-bold text-center pt-6">
            에스알 성능 보험 승인 관리
          </h3>
          <table>
            <thead>
              <tr>
                <th colSpan="6" className="header">
                  차량 기본 정보
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="subject">차명</td>
                <td className="content">{customerInfo?.carName || '-'}</td>
                <td className="subject">차량번호</td>
                <td className="content">{customerInfo?.carNbr || '-'}</td>
                <td className="subject">변경된 차량번호</td>
                <td className="content">{customerInfo?.newCarNbr || '-'}</td>
              </tr>
              <tr>
                <td className="subject">차대번호</td>
                <td colSpan="5" className="merged">
                  {customerInfo?.carVIN || '-'}
                </td>
              </tr>
              <tr>
                <td className="subject">소유자</td>
                <td className="content">{customerInfo?.customerName || '-'}</td>
                <td className="subject">연락처</td>
                <td className="content">
                  {customerInfo?.customerContact != null
                    ? CommonUtil.Formatter.phone(customerInfo?.customerContact)
                    : '-'}
                </td>
                <td className="subject">접수일자</td>
                <td className="content">{customerInfo?.receiptDate || ''}</td>
              </tr>
              <tr>
                <td className="subject">차량 인도일 (경과일수)</td>
                <td className="content">
                  {customerInfo?.buyDate != null
                    ? `${customerInfo?.buyDate} (${customerInfo?.elapsedDays}일 경과)`
                    : '-'}
                </td>
                <td className="subject">성능 킬로수</td>
                <td className="content">
                  {customerInfo?.checkDistance != null
                    ? `${formatNumber(customerInfo?.checkDistance)}Km`
                    : '-'}
                </td>
                <td className="subject">접수 킬로수</td>
                <td className="content">
                  {customerInfo?.distanceDriven != null
                    ? `${formatNumber(customerInfo?.distanceDriven)}Km`
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className="subject">성능점검일</td>
                <td className="content">{customerInfo?.checkDate || '-'}</td>
                <td className="subject">차량가액</td>
                <td colSpan="3" className="content">
                  {customerInfo?.vehicleValue != null
                    ? `${customerInfo?.vehicleValue}원`
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className="subject">증상</td>
                <td colSpan="5" className="merged">
                  {customerInfo?.note}
                </td>
              </tr>
            </tbody>
          </table>
          {/* 이미지 리스트 */}
          <div className="section_title bg-blue-100 border-blue-300">
            <p>차량 기본 사진(고객, 딜러)</p>
          </div>
          <div className="imageWrap">
            <ImageBox title={'성능기록부'} code={'S_CHECK1'} docs={docs} />
            <ImageBox
              title={'매매계약서 (업소보관용)'}
              code={'S_CONTRACT'}
              docs={docs}
            />
            <ImageBox
              title={'자동차등록증'}
              code={'C_CERTIFICATE'}
              docs={docs}
            />
            <ImageBox
              title={'자동차등록원부(갑)'}
              code={'S_CHECK2'}
              docs={docs}
            />
            <ImageBox title={'리콜'} code={'C_RECALL'} docs={docs} />
            <ImageBox
              title={'현재 기준 계기판 사진'}
              code={'C_DASHBOARD'}
              docs={docs}
            />
            <ImageBox title={'기타사진'} code={'C_ETC'} docs={docs} />
            <ImageBox
              title={'개인정보 동의서'}
              code={'C_CONSENT'}
              docs={docs}
            />
          </div>
          {copyPartnerInfo.map((partnerData) => {
            return (
              <div key={partnerData.partnerId}>
                <div className="section_title relative flex align-contents-center justify-content-center bg-blue-100 border-blue-300">
                  <p className="m-0">
                    [{partnerData.partnerName}] 입고점검사진
                  </p>
                  <Button.Default
                    className="m-0 p-button-outlined absolute top-0 bottom-0 right-0 border-none bg-transparent text-blue-600"
                    icon="pi pi-download"
                    style={{ fontWeight: 'bold' }}
                    label={`${partnerData.partnerName} 견적서 다운로드`}
                    onClick={() => saveFilesAsZip(partnerData)}
                    loading={downloadLoading}
                  />
                </div>
                <div className="imageWrap">
                  <ImageBox
                    partnerData={partnerData.partnerDocuments}
                    partner={true}
                    title={'소견서'}
                    code={'P_OPINION'}
                  />
                  <ImageBox
                    partnerData={partnerData.partnerDocuments}
                    partner={true}
                    title={'견적서'}
                    code={'P_LIST'}
                  />
                  <ImageBox
                    partnerData={partnerData.partnerDocuments}
                    partner={true}
                    title={'입고 차량전면사진'}
                    code={'P_FRONT'}
                  />
                  <ImageBox
                    partnerData={partnerData.partnerDocuments}
                    partner={true}
                    title={'입고 계기판사진'}
                    code={'P_DASHBOARD'}
                  />
                  <ImageBox
                    partnerData={partnerData.partnerDocuments}
                    partner={true}
                    title={'점검사진'}
                    code={'P_CHECK'}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="inner">
          <PartnerDocument
            isApprovalDetail={true}
            setPartnerInfo={setPartnerInfo}
            deleteImgs={partnerDeleteImgs}
            setDeleteImgs={setPartnerDeleteImgs}
            fileItems={partnerFile}
            setFileItems={setPartnerFile}
            // copyFile={copyFile}
            carNbr={detailData?.carNbr}
            checked={toggleStates}
            onToggle={handleToggleButton}
            selectedPartner={selectedPartner}
            setSelectedPartner={setSelectedPartner}
            repairData={repairData}
            setRepairData={setRepairData}
            setShowSmsType={setShowSmsType}
            setShowSmsModal={setShowSmsModal}
            setSmsData={setSmsData}
            setShowPushModal={setShowPushModal}
            setShowPushType={setShowPushType}
            smsData={smsData}
          />
        </div>
      </div>
      {showSmsMoal && showSmsType && (
        <SendSMS
          onHide={() => setShowSmsModal(!showSmsMoal)}
          smsData={smsData}
          setSmsData={setSmsData}
          customerInfo={customerInfo}
          showSmsType={showSmsType}
          postSms={postSms}
        />
      )}
      {showPushModal && showPushType && (
        <SendPush
          showPushType={showPushType}
          customerInfo={customerInfo}
          onHide={() => setShowPushModal(!showPushModal)}
          postPushMessage={postPushMessage}
          pushLoading={pushLoading}
        />
      )}
    </>
  );
};

export default ApprovalDialog;
